<template>
    <v-card-text class="black--text">
        <p>
            This is the first time you are accessing Nuvolos. Please provide some details to create a Nuvolos account associated with your
            {{ isGoogleOauth2 ? 'google' : 'university' }} login:
        </p>
        <v-form ref="form" v-model="valid" class="mt-5">
            <v-text-field v-model="givenName" :autofocus="!givenName" :counter="50" label="First Name" :rules="rules" required dense outlined></v-text-field>
            <v-text-field v-model="familyName" :counter="50" label="Last Name" :rules="rules" required outlined dense></v-text-field>
            <v-text-field :value="$auth.user.email" label="E-mail" required readonly outlined dense></v-text-field>
        </v-form>
        <v-card-actions>
            <v-btn color="primary" block :disabled="!valid" :loading="loading" @click="createAccount()">
                <v-icon class="mr-2">mdi-link-variant</v-icon>
                Create Linked Account
            </v-btn>
        </v-card-actions>

        <div class="text-center">
            <v-btn text @click="showIntercomChat" color="primary" small class="mt-2">
                <v-icon class="mr-1" small>support</v-icon>
                Ask for help
            </v-btn>
        </div>
    </v-card-text>
</template>
<script>
import { intercomMethods } from '@/mixins/intercom'

export default {
    name: 'UserNameForm',
    mixins: [intercomMethods],
    props: {
        loading: Boolean
    },
    data() {
        return {
            givenName: this.$auth.user.given_name || '',
            familyName: this.$auth.user.family_name || '',
            valid: false,
            rules: [p => p.length > 0 || 'Field can not be empty.']
        }
    },
    created() {},
    computed: {
        isGoogleOauth2() {
            return this.$route.params.provider === 'google'
        }
    },
    methods: {
        createAccount() {
            this.$emit('createAccount', { givenName: this.givenName, familyName: this.familyName })
        }
    }
}
</script>

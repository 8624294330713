export const intercomMethods = {
    methods: {
        showIntercomChat() {
            window.Intercom('update', {
                hide_default_launcher: false
            })
            window.Intercom('show')
        },
        showNewMessageIntercom(msg, preBoot) {
            if (preBoot) {
                window.Intercom('boot', {
                    app_id: 'lliic4ws',
                    email: preBoot.email,
                    user_id: preBoot.uid,
                    user_hash: preBoot.hmac,
                    name: preBoot.name || preBoot.given_name + ' ' + preBoot.family_name,
                    is_trial_user: preBoot.is_trial_user
                })
            }
            window.Intercom('update', {
                hide_default_launcher: false
            })
            window.Intercom('showNewMessage', msg)
        }
    }
}
